import React from 'react';
import { motion } from 'framer-motion';

const Star = ({ size, top, left }) => (
  <motion.div
    className="absolute rounded-full bg-white"
    style={{
      width: size,
      height: size,
      top,
      left,
    }}
    animate={{
      opacity: [0.2, 0.8, 0.2],
      scale: [1, 1.2, 1],
    }}
    transition={{
      duration: 5,
      repeat: Infinity,
      repeatType: "reverse",
      ease: "easeInOut",
      delay: Math.random() * 5,
    }}
  />
);

const AnimatedBackground = () => {
  const stars = React.useMemo(() => 
    Array.from({ length: 50 }).map((_, i) => ({
      id: i,
      size: Math.random() * 4 + 2,
      top: `${Math.random() * 100}%`,
      left: `${Math.random() * 100}%`,
    })),
    []
  );

  return (
    <div className="fixed inset-0 -z-10 overflow-hidden">
      <motion.div
        className="absolute inset-0 bg-gradient-to-br from-yellow-300 via-orange-400 to-red-500"
        animate={{
          background: [
            'linear-gradient(to bottom right, #fde047, #fb923c, #ef4444)',
            'linear-gradient(to bottom right, #fb923c, #ef4444, #fde047)',
            'linear-gradient(to bottom right, #ef4444, #fde047, #fb923c)',
            'linear-gradient(to bottom right, #fde047, #fb923c, #ef4444)',
          ],
        }}
        transition={{ repeat: Infinity, duration: 20, ease: "linear" }}
      />
      {stars.map((star) => (
        <Star key={star.id} {...star} />
      ))}
    </div>
  );
};

export default AnimatedBackground;