import React from 'react';
import { motion } from 'framer-motion';
import { Sparkles, Rocket, CreditCard, Milestone, Twitter, Send } from 'lucide-react';
import AnimatedBackground from './AnimatedBackground';

const RoadmapItem = ({ title, description, icon: Icon }) => (
  <motion.div 
    className="flex items-center mb-8"
    initial={{ opacity: 0, x: -50 }}
    whileInView={{ opacity: 1, x: 0 }}
    transition={{ duration: 0.5 }}
    viewport={{ once: true }}
  >
    <div className="bg-cyan-500 rounded-full p-3 mr-4">
      <Icon className="w-6 h-6 text-white" />
    </div>
    <div>
      <h4 className="text-xl font-bold text-cyan-100">{title}</h4>
      <p className="text-cyan-50">{description}</p>
    </div>
  </motion.div>
);

const SocialButton = ({ icon: Icon, label, color, href }) => (
  <motion.a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className={`inline-flex items-center ${color} text-white font-bold py-2 px-6 rounded-full text-lg shadow-lg transition-colors mx-2`}
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.9 }}
  >
    <Icon className="mr-2" />
    {label}
  </motion.a>
);

const CapyBuzzLandingPage = () => {
  return (
    <div className="min-h-screen relative overflow-hidden text-white">
      <AnimatedBackground />
      <header className="p-6 flex justify-between items-center relative z-10">
        <motion.h1 
          className="text-4xl font-bold text-cyan-100"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          CapyBuzz
        </motion.h1>
        <nav>
          <motion.ul 
            className="flex space-x-4"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <li><a href="#about" className="hover:text-cyan-200 transition-colors">About</a></li>
            <li><a href="#roadmap" className="hover:text-cyan-200 transition-colors">Roadmap</a></li>
            <li><a href="#community" className="hover:text-cyan-200 transition-colors">Community</a></li>
          </motion.ul>
        </nav>
      </header>

      <main className="container mx-auto px-4 py-12 relative z-10">
        <section className="text-center mb-16">
          <motion.h2 
            className="text-6xl font-bold mb-4 text-cyan-100"
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            Welcome to CapyBuzz
          </motion.h2>
          <motion.p 
            className="text-xl mb-8 text-cyan-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            The chillest memecoin in the crypto jungle!
          </motion.p>
          <motion.div
            className="inline-block mb-8"
            animate={{ rotate: [0, 10, -10, 10, 0] }}
            transition={{ repeat: Infinity, duration: 2 }}
          >
            <img src="/capybara.png" alt="Capybara mascot" className="mx-auto rounded-full border-4 border-white shadow-lg w-64 h-64" />
          </motion.div>
          <motion.div 
            className="bg-white bg-opacity-10 p-6 rounded-lg shadow-lg"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            <p className="text-cyan-50 text-lg">
              Capybuzz is a lighthearted, community-driven memecoin inspired by the internet's love for the chill, quirky capybara. Much like the capybara, known for its laid-back, social nature, Capybuzz is all about spreading good vibes and fun in the world of crypto. With its friendly mascot and playful ecosystem, Capybuzz aims to unite enthusiasts in a decentralized, meme-fueled adventure. Whether you're a seasoned trader or new to crypto, Capybuzz offers a fun, low-stakes way to dive into the market, while also supporting viral trends, memes, and community engagement.
            </p>
          </motion.div>
        </section>

        <section id="about" className="mb-16">
          <h3 className="text-3xl font-bold mb-4 text-cyan-100">About CapyBuzz</h3>
          <p className="text-lg mb-4 text-cyan-50">CapyBuzz is more than just a memecoin - it's a community of chill crypto enthusiasts inspired by the world's largest and most relaxed rodent: the capybara!</p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <motion.div 
              className="bg-white bg-opacity-10 p-6 rounded-lg shadow-lg"
              whileHover={{ scale: 1.05 }}
            >
              <Sparkles className="w-12 h-12 mb-4 text-cyan-300" />
              <h4 className="text-xl font-bold mb-2 text-cyan-100">Innovative</h4>
              <p className="text-cyan-50">Bringing capybara-level chill to the world of crypto</p>
            </motion.div>
            <motion.div 
              className="bg-white bg-opacity-10 p-6 rounded-lg shadow-lg"
              whileHover={{ scale: 1.05 }}
            >
              <Rocket className="w-12 h-12 mb-4 text-cyan-300" />
              <h4 className="text-xl font-bold mb-2 text-cyan-100">Fast-growing</h4>
              <p className="text-cyan-50">Join our rapidly expanding community of CapyBuzz enthusiasts</p>
            </motion.div>
            <motion.div 
              className="bg-white bg-opacity-10 p-6 rounded-lg shadow-lg"
              whileHover={{ scale: 1.05 }}
            >
              <CreditCard className="w-12 h-12 mb-4 text-cyan-300" />
              <h4 className="text-xl font-bold mb-2 text-cyan-100">Easy to use</h4>
              <p className="text-cyan-50">Simple transactions, perfect for crypto newbies and veterans alike</p>
            </motion.div>
          </div>
        </section>

        <section id="roadmap" className="mb-16">
          <h3 className="text-3xl font-bold mb-8 text-cyan-100">CapyBuzz Roadmap</h3>
          <div className="space-y-8">
            <RoadmapItem 
              icon={Rocket} 
              title="Q4 2024: Launch" 
              description="CapyBuzz token launch and initial exchange listings" 
            />
            <RoadmapItem 
              icon={Sparkles} 
              title="Q1 2025: Community Growth" 
              description="Expand our social media presence and launch community initiatives" 
            />
            <RoadmapItem 
              icon={CreditCard} 
              title="Q2 2025: Partnerships" 
              description="Collaborate with other projects and influencers in the crypto space" 
            />
            <RoadmapItem 
              icon={Milestone} 
              title="Q3 2025: CapyBuzz DApp" 
              description="Launch our decentralized application for enhanced token utility" 
            />
          </div>
        </section>

        <section id="community" className="text-center">
          <h3 className="text-3xl font-bold mb-4 text-cyan-100">Join the CapyBuzz Community</h3>
          <p className="text-lg mb-6 text-cyan-50">Don't miss out on the chillest crypto community around!</p>
          <div className="flex flex-wrap justify-center gap-4">
            <motion.button 
              className="bg-cyan-500 text-white font-bold py-2 px-6 rounded-full text-lg shadow-lg hover:bg-cyan-400 transition-colors"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              Buy CAPY Now
            </motion.button>
            <SocialButton
              icon={Twitter}
              label="Twitter"
              color="bg-blue-400 hover:bg-blue-500"
              href="https://x.com/BuzzCapy87162"
            />
            <SocialButton
              icon={Send}
              label="Telegram"
              color="bg-blue-500 hover:bg-blue-600"
              href="https://t.me/CapyBuzzPortal"
            />
          </div>
        </section>
      </main>

      <footer className="bg-white bg-opacity-10 text-center py-4 mt-12 relative z-10 text-cyan-50">
        <p>&copy; 2024 CapyBuzz. All rights reserved. Stay chill!</p>
      </footer>
    </div>
  );
};

export default CapyBuzzLandingPage;