import React from 'react';
import CapyBuzzLandingPage from './CapyBuzzLandingPage';

function App() {
  return (
    <div className="App">
      <CapyBuzzLandingPage />
    </div>
  );
}

export default App;